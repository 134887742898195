"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalStyles = void 0;
const styled_components_1 = require("styled-components");
const styles_1 = require("@erm-tasku/styles");
exports.GlobalStyles = styled_components_1.createGlobalStyle `
	${styles_1.globalStyles}
	
	body {
		font-family: var(--body-font);
		overflow: hidden;
		
		-webkit-touch-callout: none;
		-webkit-user-select: none;
	}
`;
