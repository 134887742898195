"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppContext = exports.AppContextProvider = exports.AppContext = void 0;
const react_1 = __importStar(require("react"));
const markers_1 = require("../lib/markers");
exports.AppContext = react_1.createContext({});
const AppContextProvider = ({ children }) => {
    const [markers, setMarkers] = react_1.useState([]);
    const [selectedMarker, setSelectedMarker] = react_1.useState(null);
    const [visitedMarkers, setVisitedMarkers] = react_1.useState([]);
    react_1.useEffect(() => {
        setVisitedMarkers([...visitedMarkers, selectedMarker]);
    }, [selectedMarker]);
    react_1.useEffect(() => {
        Promise.all([
            fetch('assets/kukruse-media.json').then((r) => r.json()),
            fetch('assets/kukruse-markers.json').then((r) => r.json()),
        ]).then(([_media, _markers]) => {
            const markers = markers_1.assignHighlights(_markers, _media);
            const boundMarkers = markers_1.assignMarkerBounds(markers);
            setMarkers(boundMarkers);
        });
    }, []);
    return (react_1.default.createElement(exports.AppContext.Provider, { value: { markers, selectedMarker, setSelectedMarker, visitedMarkers } }, children));
};
exports.AppContextProvider = AppContextProvider;
const useAppContext = () => react_1.useContext(exports.AppContext);
exports.useAppContext = useAppContext;
