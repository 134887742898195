"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignMarkerBounds = exports.assignHighlights = void 0;
function assignHighlights(markers, media) {
    return markers.map((marker) => {
        const d = media.find(m => m.id === marker.highlightId);
        Object.assign(marker, d);
        if (!marker.highlightId) {
            marker.highlightId = marker.id;
        }
        return marker;
    });
}
exports.assignHighlights = assignHighlights;
function assignMarkerBounds(markers) {
    const fullWidth = 27100;
    const fullHeight = 14342;
    const ratio = fullHeight / fullWidth;
    const factor = 4;
    return markers.map((m) => (Object.assign(Object.assign({}, m), { x: m.x * factor, y: m.y * factor, relX: (m.x * factor) / fullWidth, relY: ((m.y * factor) / fullHeight) * ratio, bounds: m.bounds && multiplyBounds(m.bounds, factor), relBounds: m.bounds &&
            relativeBounds(multiplyBounds(m.bounds, factor), fullWidth, fullHeight) })));
}
exports.assignMarkerBounds = assignMarkerBounds;
function multiplyBounds(bounds, r) {
    return {
        x: bounds.x * r,
        y: bounds.y * r,
        width: bounds.width * r,
        height: bounds.height * r,
    };
}
function relativeBounds(bounds, fullWidth, fullHeight) {
    const r = fullHeight / fullWidth;
    return {
        x: bounds.x / fullWidth,
        y: (bounds.y / fullHeight) * r,
        width: bounds.width / fullWidth,
        height: (bounds.height / fullHeight) * r,
    };
}
